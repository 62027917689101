<template>
  <div>
    <BetaWarning />
    <v-container>
      <ProgressCircular
        v-if="gettingInitialTranslations"
        :loading="gettingInitialTranslations"
      />
      <v-row v-else>
        <v-col
          cols="12"
          md="6"
        >
          <v-select
            :label="$t('translations')"
            hide-details
            outlined
            :items="translationTypes"
            item-text="title"
            item-value="key"
            v-model="translationType"
            background-color="white"
          />
        </v-col>
        <v-col
          cols="12"
          md="auto"
          class="grow"
          v-if="false"
        >
          <v-autocomplete
            :items="availableLanguages"
            v-model="selectedFromLanguage"
            hide-details
            outlined
            :label="$t('translateFrom')"
            item-value="code"
            item-text="name"
            :filter="languageSelectionFilter"
            :no-data-text="$t('noLanguagesAvailable')"
          >
            <template v-slot:item="{ item }">
              <div class="pb-1">
                <div style="font-weight: 500;">
                  {{ item.name }}
                </div>
                <div class="text--secondary">
                  {{ item.native }}
                </div>
                <div class="text--secondary text-uppercase">
                  {{ item.code }}
                </div>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="auto"
          class="grow"
          v-if="false"
        >
          <v-autocomplete
            :items="availableLanguages"
            v-model="selectedToLanguage"
            hide-details
            outlined
            :label="$t('translateTo')"
            item-value="code"
            item-text="name"
            :filter="languageSelectionFilter"
            :no-data-text="$t('noLanguagesAvailable')"
          >
            <template v-slot:item="{ item }">
              <div class="pb-1">
                <div style="font-weight: 500;">
                  {{ item.name }}
                </div>
                <div class="text--secondary">
                  {{ item.native }}
                </div>
                <div class="text--secondary text-uppercase">
                  {{ item.code }}
                </div>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            :items="availableLanguages"
            v-model="selectedTranslationLanguage"
            hide-details
            outlined
            :label="$t('translationLanguage')"
            item-value="code"
            item-text="name"
            :filter="languageSelectionFilter"
            background-color="white"
            :no-data-text="$t('noLanguagesAvailable')"
          >
            <template v-slot:item="{ item }">
              <div class="pb-1">
                <div style="font-weight: 500;">
                  {{ item.name }}
                </div>
                <div
                  class="text--secondary"
                  v-if="item.native"
                >
                  {{ item.native }}
                </div>
                <div
                  class="text--secondary text-uppercase"
                  v-if="item.code"
                >
                  {{ item.code }}
                </div>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row
        v-if="selectedTranslationLanguage === null || translationType === null"
        justify="center"
      >
        <v-col cols="auto">
          {{ $t('pleaseSelectWhatYouWantToTranslate') }}
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          cols="9"
        >
          <v-text-field
            v-model="searchStringProxy"
            :label="$t('search')"
            outlined
            background-color="white"
            @keydown.enter="search"
            hint="Hint: Press enter after typing to search."
            persistent-hint
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="3"
        >
          <v-btn
            block
            outlined
            style="background-color: white;"
            x-large
            @click="search()"
          >
            {{$t('search')}}
          </v-btn>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="showActiveOnly"
            :label="$t('showActiveItemsOnly')"
          />
        </v-col>
        <template v-for="translation in slicedTranslations">
          <v-col
            :key="translation._id"
            cols="12"
          >
            <TranslationCard :translation="translation" />
          </v-col>
        </template>
      </v-row>
      <div
        v-if="translations.length > 0 && translations.length > slicedTranslations.length"
        v-intersect.quiet="loadMoreTranslations"
      >
        <div class="text-center">
          <v-progress-circular indeterminate />
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import TranslationCard from "@/components/translation/TranslationCard"
import ProgressCircular from "@/components/common/ProgressCircular"
import BetaWarning from "@/components/common/BetaWarning"

export default {
  name: "Translations",
  components: {
    BetaWarning,
    ProgressCircular,
    TranslationCard
  },
  data() {
    return {
      translationType: null,
      selectedFromLanguage: null,
      selectedToLanguage: null,
      selectedTranslationLanguage: null,
      gettingInitialTranslations: false,
      length: 5,
      searchStringProxy: "",
      searchString: "",
      showActiveOnly: true
    }
  },
  computed: {
    translations() {
      if(this.selectedTranslationLanguage === null || this.selectedTranslationLanguage === undefined) {
        return []
      }
      if(this.translationType === null || this.translationType === undefined || this.translationType === 'all') {
        return []
      }
      let translations = this.$store.getters.translations(this.selectedTranslationLanguage, this.translationType)
      if(this.translationType !== 'all' && this.translationType !== null && this.translationType !== undefined) {
        translations = translations.filter(translation => translation.type === this.translationType)

        if(this.showActiveOnly && Array.isArray(translations) && translations.length > 0 && translations[0].hasOwnProperty('status')) {
          translations = translations.filter(translation => translation.status === true)
        }

      }
      if(this.selectedTranslationLanguage !== 'all' && this.selectedTranslationLanguage !== null && this.selectedTranslationLanguage !== undefined) {
        translations = translations.filter(translation => translation.language === this.selectedTranslationLanguage)
      }
      return translations
    },
    slicedTranslations() {
      if(this.searchQuery !== null && this.searchQuery !== undefined && this.searchQuery.length > 0) {
        const query = this.searchQuery.reduce((accumulator, item) => accumulator + item.value, '')

        return this.translations.filter(translation => {
          if (translation.item) {
            if (translation.item.title !== null && translation.item.title !== undefined) {
              return translation.item.title.toLowerCase().includes(query.toLowerCase())
            }
            if (translation.item.name !== null && translation.item.name !== undefined) {
              return translation.item.name.toLowerCase().includes(query.toLowerCase())
            }
            if (translation.item.label !== null && translation.item.label !== undefined) {
              return translation.item.label.toLowerCase().includes(query.toLowerCase())
            }
          }
          return false
        })
      }

      else if(this.searchString.length > 0) {
        return this.translations.filter(translation => {
          if (translation.item) {
            if (translation.item.title !== null && translation.item.title !== undefined) {
              return translation.item.title.toLowerCase().includes(this.searchString.toLowerCase())
            }
            if (translation.item.name !== null && translation.item.name !== undefined) {
              return translation.item.name.toLowerCase().includes(this.searchString.toLowerCase())
            }
            if (translation.item.label !== null && translation.item.label !== undefined) {
              return translation.item.label.toLowerCase().includes(this.searchString.toLowerCase())
            }
          }
          return false
        })
      }
      
      return this.translations.slice(0, this.length)
    },
    searchQuery() {
      return this.$store.state.searchQuery
    },
    translationTypes() {
      return [
        {
          title: this.$t('pleaseSelectTranslationTypeToStart'),
          key: 'all'
        },
        {
          title: this.$t('items'),
          key: 'item'
        },
        {
          title: this.$t('categories'),
          key: 'category'
        },
        {
          title: this.$t('variations'),
          key: 'variation'
        },
        {
          title: this.$t('attributes'),
          key: 'attribute'
        }
      ]
    },
    whitelistedProperties() {
      return ['title', 'name', 'label', 'description', 'description.value', 'description.summary', 'summary']
    },
    availableLanguages() {
      return [
        {
          name: this.$t('pleaseSelectLanguageToStart'),
          native: null,
          code: null,
          flag: ''
        },
        {
          name: this.$t('icelandic'),
          native: 'Íslenska',
          code: 'is',
          flag: ''
        },
        {
          name: this.$t('english'),
          native: 'English',
          code: 'en',
          flag: ''
        },
				{
          name: this.$t('swedish'),
          native: 'Svenska',
          code: 'se',
          flag: ''
        },
				{
          name: this.$t('danish'),
          native: 'Danish',
          code: 'dk',
          flag: ''
        },
      ]
    }
  },
  methods: {
    languageSelectionFilter(item, query) {
      const itemName = item.name.toLowerCase()
      const itemNative = item.native ? item.native.toLowerCase() : ''
      const itemCode = item.code ? item.code.toLowerCase() : ''

      const searchText = query.toLowerCase()

      return itemName.indexOf(searchText) > -1 || itemNative.indexOf(searchText) > -1 || itemCode.indexOf(searchText) > -1
    },
    loadMoreTranslations() {
      this.length = this.length + 5
    },
    search() {
      this.searchString = this.searchStringProxy
    }
  },
  beforeMount() {
    this.gettingInitialTranslations = true
    this.$store.dispatch('getOrganizationTranslations').then(() => {
      //
    }).finally(() => {
      this.gettingInitialTranslations = false
    })
    this.translationType = 'all'
  }
}
</script>

<style scoped>

</style>
